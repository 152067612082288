import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { clearAccessToken, clearEmailInStorage } from "../../utils/localStorage";
import { eraseCookie } from "../../utils/cookie";

import "./Header.scss";
import { StateContext } from "../../reducer/constants";
import { changeNetworkAtMetamask, getShortAddress, NETWORK } from "../../utils/network";
import ModalWallet from "../ModalWallet/ModalWallet";

interface Props {
    onConnect: () => void;
    onDisconnect: () => void;
}

const Header = ({ onConnect, onDisconnect }: Props) => {
    const { chain, currentAddress } = useContext(StateContext);
    const navigate = useNavigate();
    const [isLogoutClicked, setIsLogoutClicked] = useState(false);
    const [hasModal, setHasModal] = useState(false);

    const handleLogoutClicked = () => {
        setIsLogoutClicked(true);
    };
    const handleLogoutUnclicked = () => {
        setIsLogoutClicked(false);
    };
    const handleLogout = () => {
        clearAccessToken();
        clearEmailInStorage();
        eraseCookie("refresh");
        navigate("/email");
    };

    const handleConnect = async () => {
        if (currentAddress) {
            await changeNetworkAtMetamask(NETWORK.step_network);
        } else {
            onConnect();
        }
    };

    return (
        <div className="header">
            <img alt="logo" className="header__logo" src="/img/logo.svg" />
            <div className="header__buttons">
                {currentAddress && chain === NETWORK.step_network ? (
                    <div className="header__button" onClick={onDisconnect}>
                        {getShortAddress(currentAddress)}
                    </div>
                ) : (
                    <button type="button" className="header__button" onClick={handleConnect}>
                        <span>Connect</span>
                    </button>
                )}

                <div className="header__logout-container">
                    <button type="button" className="header__button" onClick={handleLogoutClicked}>
                        Logout
                    </button>
                </div>

                {isLogoutClicked && (
                    <div className="header__logout-Wrapper">
                        <div className="logout__question">Are you sure?</div>
                        <div className="buttonsWrapper">
                            <span className="question--yes" onClick={handleLogout}>
                                Yes
                            </span>
                            <span className="question--no" onClick={handleLogoutUnclicked}>
                                No
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <ModalWallet
                wallet={currentAddress}
                visible={hasModal}
                onClose={() => setHasModal(false)}
                title="Update wallet?"
            />
        </div>
    );
};

export default Header;
