import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import EmailCodeInput from "../EmailCodeInput/EmailCodeInput";
import { sendRefCode } from "../../utils/email";

import "./RefCode.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getCookie } from "../../utils/cookie";
import { StateContext } from "../../reducer/constants";

const getCodeLength = (code: string) => code.split("").filter((v) => v !== " ").length;

const INPUT_LENGTH = 8;

const RefCode = () => {
    const { remoteConfig } = useContext(StateContext);
    const [code, setCode] = React.useState("");
    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const refCodeCookie = getCookie("r");
        if (refCodeCookie) {
            setCode(refCodeCookie);
        }
    }, []);

    const handleOpenNextScreen = () => {
        navigate(remoteConfig.referralFirstLogin ? "/email-code" : "/dashboard");
    };

    const handleVerifyCode = async () => {
        const response = await sendRefCode(code);
        if (response) {
            handleOpenNextScreen();
        } else {
            setIsError(true);
            setCode("");
        }
    };

    useEffect(() => {
        if (isError) {
            setTimeout(() => setIsError(false), 1000);
        }
    }, [isError]);

    useEffect(() => {
        if (getCodeLength(code) === INPUT_LENGTH) {
            handleVerifyCode();
        }
    }, [code]);

    useEffect(() => {
        document.body.style.backgroundColor = "#F9D549";
    }, []);

    const marginTop = useMemo(
        () => (width > 600 ? Math.max(height - 582 - 100, 50) : Math.max(height - 350 - 100, 50)),
        [width, height]
    );

    return (
        <div className="ref-code">
            <img className="ref-code__logo" src="/img/logo.svg" />
            <div className="email-code__info">
                <div className="ref-code__title">Do you have a Referral Code?</div>
                <div className="ref-code__subTitle">
                    You can enter ref code belove or just <span onClick={handleOpenNextScreen}>skip this step</span>
                </div>
                <EmailCodeInput
                    isSmall
                    needChangeColorOnError={false}
                    hasError={isError}
                    acceptOnlyNumber={false}
                    length={INPUT_LENGTH}
                    disabled={getCodeLength(code) === INPUT_LENGTH}
                    value={code}
                    onChange={setCode}
                    inputType="text"
                />
            </div>
        </div>
    );
};

export default RefCode;
