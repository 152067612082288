import { refreshAccessToken } from "./email";

const EMAIL = "email";
const ACCESS_TOKEN = "access-token";
const ACCESS_TOKEN_EXPIRES = "access-token-expires";

export const setAccessToken = (token: string, expires: number) => {
    localStorage.setItem(ACCESS_TOKEN, token);
    localStorage.setItem(ACCESS_TOKEN_EXPIRES, `${Date.now() + expires * 1000}`);
};

export const clearAccessToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN_EXPIRES);
};

export const getAccessToken = async () => {
    const expires = +(localStorage.getItem(ACCESS_TOKEN_EXPIRES) ?? 0);
    if (Date.now() > expires) {
        clearAccessToken();

        const tokens = await refreshAccessToken();
        if (tokens) {
            setAccessToken(tokens.access.token, tokens.access.expires);
            return tokens.access.token;
        }

        return undefined;
    }

    return localStorage.getItem(ACCESS_TOKEN);
};

export const setEmailToStorage = (email: string) => {
    localStorage.setItem(EMAIL, email);
};
export const getEmailInStorage = () => {
    return localStorage.getItem(EMAIL) as string | undefined;
};
export const clearEmailInStorage = () => {
    localStorage.removeItem(EMAIL);
};
