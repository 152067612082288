import React from "react";

import "./Button.scss";
import cn from "classnames";

interface ButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
    maxWidth?: number;
    height?: number;
    className?: string;
}

const Button = ({ onClick, disabled, children, maxWidth, height, className }: ButtonProps) => {
    return (
        <div
            className={cn("welcome-button", className, { "welcome-button--disabled": disabled })}
            style={{ maxWidth: maxWidth ?? "auto", height: height ?? "auto" }}
            onClick={disabled ? undefined : onClick}
        >
            {children}
        </div>
    );
};

export default Button;
