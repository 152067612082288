import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";

import Button from "../Button/Button";
import EmailDoneModalContainer from "../EmailDoneModalContainer/EmailDoneModalContainer";
import { addWallet, getUserInformation } from "../../utils/email";
import { DispatchContext } from "../../reducer/constants";
import { Actions } from "../../reducer";

import "./ModalWallet.scss";

interface ModalWalletProps {
    wallet?: string;
    visible: boolean;
    onClose: () => void;
    title?: string;
}

const ModalWallet = ({ wallet, visible, onClose, title = "Enter your wallet:" }: ModalWalletProps) => {
    const dispatch = useContext(DispatchContext);
    const [currentWallet, setCurrentWallet] = useState<string | undefined>(wallet);
    const [isError, setIsError] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentWallet(e.target.value.trim());
    };

    useEffect(() => {
        if (wallet) {
            setCurrentWallet(wallet);
        }
    }, [wallet]);

    const handleApprove = async () => {
        if (currentWallet && Web3.utils.isAddress(currentWallet)) {
            setIsError(false);
            const response = await addWallet(currentWallet);
            if (response) {
                dispatch({ type: Actions.AddUserInfo, payload: await getUserInformation() });
                onClose();
            } else {
                setCurrentWallet("");
                setIsError(true);
            }
        } else {
            setCurrentWallet("");
            setIsError(true);
        }
    };

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false);
            }, 5000);
        }
    }, [isError]);

    return (
        <EmailDoneModalContainer visible={visible} onClose={onClose} className="modal-wallet">
            <div className="modal-wallet__title">{title}</div>
            <div className="modal-wallet__content">
                <input
                    className="modal-wallet__input"
                    value={currentWallet}
                    onChange={handleEmailChange}
                    placeholder="0x0000000000000000000000000000000000000000"
                />
                <Button onClick={handleApprove} disabled={!currentWallet?.length || isError} height={56}>
                    {isError ? "Incorrect wallet" : "Save"}
                </Button>
            </div>
        </EmailDoneModalContainer>
    );
};

export default ModalWallet;
