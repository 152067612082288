import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BN from "bn.js";

import { DispatchContext, StateContext } from "../../reducer/constants";
import { getUserInformation, joinWhitelist } from "../../utils/email";
import { Actions } from "../../reducer";
import Header from "../Header/Header";
import Button from "../Button/Button";
import { toHRNumber } from "../../utils/bigNumber";

import "./Whitelist.scss";

interface Props {
    onConnect: () => void;
    onDisconnect: () => void;
}

const MAX_BALANCE = 5000;

const Whitelist = ({ onConnect, onDisconnect }: Props) => {
    const { userInfo, stakingContract, currentAddress, tokenContract } = useContext(StateContext);
    const [isJoined, setIsJoined] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [balance, setBalance] = useState<number | undefined>(undefined);
    const dispatch = useContext(DispatchContext);
    const navigate = useNavigate();

    const onMount = async () => {
        document.body.style.backgroundColor = "#F9D549";

        if (!userInfo) {
            setIsLoading(true);
            const information = await getUserInformation();
            dispatch({ type: Actions.AddUserInfo, payload: information });
            setIsJoined(!!information?.inWhitelist);
            setIsLoading(false);

            if (!information?.email) {
                navigate("/email");
            }
        } else {
            setIsJoined(!!userInfo?.inWhitelist);
        }
    };

    useEffect(() => {
        onMount();
    }, [currentAddress, userInfo]);

    const updateBalance = async () => {
        if (stakingContract && tokenContract && currentAddress) {
            const newBalance = await stakingContract?.methods.balanceOf(currentAddress).call();
            const decimals = 18;
            setBalance(toHRNumber(new BN(newBalance), decimals));
        }
    };

    useEffect(() => {
        updateBalance();
    }, [stakingContract, tokenContract, currentAddress]);

    const handleConnect = () => {
        onConnect();
    };

    const handleGoToStake = () => {
        window.open("https://staking.step.app/");
    };
    const handleJoin = async () => {
        if (currentAddress && balance) {
            setIsLoading(true);
            const res = await joinWhitelist(currentAddress, balance);
            setIsLoading(false);
            if (res) {
                setIsJoined(true);
            }
        }
    };

    const noBalance = balance === undefined;
    const notEnoughBalance = !noBalance && balance >= 0 && balance < MAX_BALANCE;
    const enoughBalance = !noBalance && balance >= MAX_BALANCE;

    const renderButton = () => {
        if (isLoading) {
            return (
                <Button disabled className="whitelist__button whitelist__button--enough" height={56} maxWidth={428}>
                    Loading...
                </Button>
            );
        }

        if (isJoined) {
            return (
                <Button disabled className="whitelist__button whitelist__button--enough" height={56} maxWidth={428}>
                    JOINED
                </Button>
            );
        }

        if (noBalance) {
            return (
                <Button className="whitelist__button" height={56} maxWidth={428} onClick={handleConnect}>
                    CONNECT WALLET
                </Button>
            );
        }

        if (notEnoughBalance) {
            return (
                <Button
                    className="whitelist__button whitelist__button--notEnough"
                    height={56}
                    maxWidth={428}
                    onClick={handleGoToStake}
                >
                    GO TO STAKING PAGE
                </Button>
            );
        }

        if (enoughBalance) {
            return (
                <Button
                    className="whitelist__button whitelist__button--enough"
                    height={56}
                    maxWidth={428}
                    onClick={handleJoin}
                >
                    JOIN THE WHITELIST
                </Button>
            );
        }
    };

    return (
        <div className="whitelist">
            <Header onConnect={onConnect} onDisconnect={onDisconnect} />
            <div className="whitelist__content">
                <div className="whitelist__title">Join the Whitelist for Private Beta:</div>
                {noBalance && (
                    <>
                        <div className="whitelist__subtitle">WHITELIST STARTING</div>
                        <div className="whitelist__description">FROM 5,000 STAKED FITFI</div>
                    </>
                )}
                {notEnoughBalance && (
                    <>
                        <div className="whitelist__subtitle">YOU STAKED {balance.toLocaleString("en-us")} FITFI</div>
                        <div className="whitelist__description">
                            AND NEED {(MAX_BALANCE - balance).toLocaleString("en-us")} MORE TO JOIN THE WHITELIST
                        </div>
                    </>
                )}
                {enoughBalance && (
                    <>
                        <div className="whitelist__subtitle">YOU STAKED {balance.toLocaleString("en-us")} FITFI</div>
                        <div className="whitelist__description">AND HAVE CHANCES TO JOIN THE WHITELIST</div>
                    </>
                )}

                <div className="whitelist__button-container">{renderButton()}</div>
            </div>
        </div>
    );
};

export default Whitelist;
