import React from "react";
import Web3 from "web3";

import { ActionType, ReducerState } from "./index";

export const initialState: ReducerState = {
    email: undefined,
    userInfo: undefined,
    remoteConfig: {
        loginEnabled: true,
        referralFirstLogin: false,
    },
    currentAddress: undefined,
    chain: undefined,
    web3: new Web3(Web3.givenProvider),
    tokenContract: undefined,
    stakingContract: undefined,
};

export const StateContext = React.createContext(initialState);
export const DispatchContext = React.createContext<React.Dispatch<ActionType>>(() => null);

export const TIMEOUT_DELAY = 3000;
