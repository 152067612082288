import Web3 from "web3";

// export type NetworkType = "avalanche";
export type NetworkType = "step_network";

export const NETWORK: Record<NetworkType, NetworkType> = {
    // avalanche: "avalanche",
    step_network: "step_network",
};

export const networkNames = {
    // [NETWORK.avalanche]: "Avalanche",
    [NETWORK.step_network]: "Step Network",
};

const inverse = (obj: Record<any, any>) => Object.fromEntries(Object.entries(obj).map((a) => a.reverse()));

export const idToNetwork: Record<number, NetworkType> = {
    // 43114: NETWORK.avalanche,
    // 43113: NETWORK.avalanche,
    1234: NETWORK.step_network,
};

export const networkToId = inverse(idToNetwork);

export const networkInfo = {
    // [NETWORK.avalanche]: {
    //     chainName: "Avalanche C-Chain",
    //     chainId: Web3.utils.toHex(networkToId[NETWORK.avalanche]),
    //     rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    //     blockExplorerUrls: ["https://snowtrace.io"],
    //     nativeCurrency: {
    //         name: "AVAX",
    //         symbol: "AVAX",
    //         decimals: 18,
    //     },
    // },
    [NETWORK.step_network]: {
        chainName: "Step Network",
        chainId: Web3.utils.toHex(networkToId[NETWORK.step_network]),
        rpcUrls: ["https://rpc.step.network"],
        blockExplorerUrls: ["https://stepscan.io/"],
        nativeCurrency: {
            name: "FITFI",
            symbol: "FITFI",
            decimals: 18,
        },
    },
};

export const changeNetworkAtMetamask = async (networkName: NetworkType) => {
    try {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(networkToId[networkName]) }],
        });
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        // @ts-ignore
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [networkInfo[networkName]],
                });
            } catch (addError) {
                console.error(addError);
            }
        }
    }
};

export const getShortAddress = (address: string | null) =>
    address ? `${address.slice(0, 6)}...${address.slice(address.length - 3)}` : "";
