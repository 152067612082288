import React, { useEffect, useRef } from "react";

import "./EmailCodeInput.scss";
import useDigitInput from "react-digit-input";
import cn from "classnames";
import { TIMEOUT_DELAY } from "../../reducer/constants";

interface EmailCodeInputProps {
    value: string;
    disabled: boolean;
    length: number;
    acceptOnlyNumber: boolean;
    hasError?: boolean | null;
    needChangeColorOnError?: boolean;
    onChange: React.Dispatch<React.SetStateAction<string>>;
    isSmall?: boolean;
    inputType: string;
}

const EmailCodeInput = ({
    value,
    disabled,
    onChange,
    length,
    acceptOnlyNumber,
    hasError = false,
    needChangeColorOnError = true,
    isSmall = false,
    inputType,
}: EmailCodeInputProps) => {
    const firstInputRef = useRef<any>(null);
    const lastInputRef = useRef<any>(null);
    const digits = useDigitInput({
        acceptedCharacters: acceptOnlyNumber ? /^[0-9]$/ : /^[a-zA-Z0-9]$/,
        length,
        value,
        onChange,
    });

    useEffect(() => {
        if (hasError) {
            setTimeout(() => firstInputRef.current?.childNodes[0].focus(), TIMEOUT_DELAY);
        }
    }, [hasError]);

    const getRefForInput = (i: number, maxLength: number) => {
        if (i === 0) {
            return firstInputRef;
        }
        if (i === maxLength - 1) {
            return lastInputRef;
        }
        return undefined;
    };

    return (
        <div
            className={cn("email-code-input", {
                "email-code-input--disabled": disabled,
                "email-code-input--error": hasError && needChangeColorOnError,
                "email-code-input--success": hasError === false && !!lastInputRef.current?.childNodes[0].value,
                "email-code-input--small": isSmall,
            })}
        >
            {new Array(length).fill(0).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div ref={getRefForInput(i, length)} key={i}>
                    <input type={inputType} {...digits[i]} autoFocus={i === 0} />
                </div>
            ))}
        </div>
    );
};

export default EmailCodeInput;
