import React from "react";
import Modal from "react-modal";
import cn from "classnames";

import "./EmailDoneModalContainer.scss";

interface EmailDoneModalContainerProps {
    visible: boolean;
    onClose: () => void;
    children: React.ReactNode;
    className?: string;
}

const EmailDoneModalContainer = ({ visible, onClose, children, className }: EmailDoneModalContainerProps) => {
    return (
        <Modal
            isOpen={visible}
            onRequestClose={onClose}
            className={cn("email-done-modal-container", className)}
            overlayClassName="email-done-modal-container__overlay"
        >
            <div className="email-done-modal-container__close" onClick={onClose}>
                CLOSE
            </div>
            <>{children}</>
        </Modal>
    );
};

export default EmailDoneModalContainer;
