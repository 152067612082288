import axios from "axios";
import { UserInformationType } from "./types";
import { getAccessToken } from "./localStorage";

// const ENDPOINT = "https://api.step.app/dev/v1";
const ENDPOINT = "https://api.sports.gg/v1";

const credentialConfig = {
    withCredentials: true,
};

const authConfig = (token: string) => ({
    headers: { Authorization: `Bearer ${token}` },
});

const responseWrapper = async (f: Promise<any>, needErrorMessage?: boolean) => {
    try {
        const response = await f;
        return response.data;
    } catch (e: any) {
        const errorMessage = e?.response?.data?.message ?? "Error";
        if (needErrorMessage) {
            return { responseError: errorMessage };
        }
    }
};

export const sendEmailCode = async (email: string) =>
    responseWrapper(axios.get(`${ENDPOINT}/auth/otp-code?email=${email}`));

export const verifyEmailCode = async (email: string, code: string) =>
    responseWrapper(axios.get(`${ENDPOINT}/auth/token?email=${email}&code=${code}`, credentialConfig));

export const sendRefCode = async (code: string) => {
    const accessToken = await getAccessToken();
    return responseWrapper(axios.patch(`${ENDPOINT}/user/me`, { referrer: code }, authConfig(accessToken)));
};

export const addWallet = async (wallet: string) => {
    const accessToken = await getAccessToken();
    return responseWrapper(axios.patch(`${ENDPOINT}/user/me`, { wallet }, authConfig(accessToken)));
};

export const joinWhitelist = async (wallet: string, amount: number) => {
    const accessToken = await getAccessToken();
    return responseWrapper(
        axios.patch(`${ENDPOINT}/user/me`, { whitelist: { wallet, amount } }, authConfig(accessToken))
    );
};

export const getUserInformation: () => Promise<UserInformationType> = async () => {
    const accessToken = await getAccessToken();
    return responseWrapper(axios.get(`${ENDPOINT}/user/me`, authConfig(accessToken)));
};

export const refreshAccessToken = async () => {
    // const refreshToken = getCookie("refresh");
    return responseWrapper(axios.post(`${ENDPOINT}/auth/token/refresh`, {}, credentialConfig));
};

export const isEmail = (email?: string) => {
    const re =
        // eslint-disable-next-line
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !!(email?.length && email.toLowerCase().match(re));
};
