import React, { useCallback, useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Web3Modal, { IProviderOptions } from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import bgImage from "../../assets/mainBg.png";
import bgBlured from "../../assets/bgBlured.png";
import bgBluredLight from "../../assets/bgBluredLight.png";

import Email from "../Email/Email";
import EmailCode from "../EmailCode/EmailCode";
import EmailDone from "../EmailDone/EmailDone";
import RefCode from "../RefCode/RefCode";
import { setCookie } from "../../utils/cookie";
import Whitelist from "../Whitelist/Whitelist";
import { changeNetworkAtMetamask, idToNetwork, NETWORK, networkInfo } from "../../utils/network";
import { DispatchContext, StateContext } from "../../reducer/constants";
import { Actions } from "../../reducer";

import ERC20 from "../../constants/ERC20.json";
import STEP_APP_STAKING from "../../constants/StepAppStaking.json";
import ADDRESSES from "../../constants/address";

import "./App.scss";

const getRefCode = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("r");
};

const providerOptions: IProviderOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            rpc: {
                // 43113: "https://api.avax-test.network/ext/bc/C/rpc",
                // 43114: networkInfo[NETWORK.avalanche].rpcUrls[0],
                12345: networkInfo[NETWORK.step_network].rpcUrls[0],
            },
        },
    },
};
const web3Modal = new Web3Modal({ network: NETWORK.step_network, cacheProvider: true, providerOptions });

const App = () => {
    const [refCode, _] = useState<string | null>(getRefCode());
    const { web3 } = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const location = useLocation();
    const onDisconnect = async () => {
        // @ts-ignore
        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            // @ts-ignore
            await web3.currentProvider.close();
        }
        dispatch({ type: Actions.SetCurrentAddress, payload: undefined });
        await web3Modal.clearCachedProvider();
    };
    const subscribeProvider = async (newProvider: any) => {
        if (!newProvider.on) {
            return;
        }
        newProvider.on("close", () => {
            onDisconnect();
        });
        newProvider.on("accountsChanged", async (accounts: string[]) => {
            dispatch({ type: Actions.SetCurrentAddress, payload: accounts[0] });
        });
        newProvider.on("chainChanged", async (hexChainId: string) => {
            const chainId = Web3.utils.hexToNumber(hexChainId);
            dispatch({ type: Actions.SetChain, payload: idToNetwork[chainId] });
        });
    };
    const onConnect = async () => {
        const provider = await web3Modal.connect();
        await subscribeProvider(provider);

        const newWeb3: Web3 = new Web3(provider);
        dispatch({ type: Actions.SetWeb3, payload: newWeb3 });

        await changeNetworkAtMetamask(NETWORK.step_network);

        const accounts = await newWeb3.eth.getAccounts();
        dispatch({ type: Actions.SetCurrentAddress, payload: accounts[0] });
        const chainId = await newWeb3.eth.getChainId();
        dispatch({ type: Actions.SetChain, payload: idToNetwork[chainId] });

        dispatch({
            type: Actions.SetTokenContract,
            payload: new newWeb3.eth.Contract(ERC20 as any, ADDRESSES.MOCK_TOKEN),
        });
        dispatch({
            type: Actions.SetStakingContract,
            payload: new newWeb3.eth.Contract(STEP_APP_STAKING as any, ADDRESSES.STEP_APP_STAKING),
        });
    };

    useEffect(() => {
        if (refCode) {
            setCookie("r", refCode, 60 * 60);
        }
    }, [refCode]);

    const getBgAsset = useCallback(() => {
        if (location.pathname === "/") {
            return bgImage;
        }
        if (location.pathname.includes("dashboard") || location.pathname.includes("whitelist")) {
            return bgBlured;
        }
        return bgBluredLight;
    }, [location.pathname]);

    return (
        <div className="app">
            <Routes>
                <Route path="/" element={<Email />} />
                <Route path="/email-code" element={<EmailCode />} />
                <Route path="/ref-code" element={<RefCode />} />
                <Route path="/dashboard" element={<EmailDone onConnect={onConnect} onDisconnect={onDisconnect} />} />
                <Route path="/whitelist" element={<Whitelist onConnect={onConnect} onDisconnect={onDisconnect} />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <img alt="background" src={getBgAsset()} className="mainBg" />
        </div>
    );
};

export default App;
