import Web3 from "web3";
import { Contract } from "web3-eth-contract";
import { UserInformationType } from "../utils/types";

// eslint-disable-next-line no-shadow
export enum Actions {
    AddEmail = "ADD_EMAIL",
    AddUserInfo = "ADD_USER_INFO",
    SetConfig = "SET_CONFIG",
    SetCurrentAddress = "SET_CURRENT_ADDRESS",
    SetChain = "SET_CHAIN",
    SetWeb3 = "SET_WEB3",
    SetTokenContract = "SET_TOKEN_CONTRACT",
    SetStakingContract = "SET_STAKING_CONTRACT",
}

export type ActionType =
    | {
          type: Actions.AddEmail;
          payload: string;
      }
    | {
          type: Actions.AddUserInfo;
          payload: UserInformationType;
      }
    | {
          type: Actions.SetCurrentAddress;
          payload: string | undefined;
      }
    | {
          type: Actions.SetChain;
          payload: string | undefined;
      }
    | {
          type: Actions.SetWeb3;
          payload: Web3;
      }
    | {
          type: Actions.SetTokenContract;
          payload: Contract;
      }
    | {
          type: Actions.SetStakingContract;
          payload: Contract;
      }
    | {
          type: Actions.SetConfig;
          payload: Record<string, any>;
      };

export interface ReducerState {
    email?: string;
    userInfo?: UserInformationType;
    remoteConfig: {
        loginEnabled?: boolean;
        referralFirstLogin?: boolean;
    };
    currentAddress?: string;
    chain?: string;
    web3?: Web3;
    tokenContract?: Contract;
    stakingContract?: Contract;
}

const reducer = (state: ReducerState, action: ActionType) => {
    const { type, payload } = action;
    switch (type) {
        case Actions.AddUserInfo:
            return {
                ...state,
                userInfo: payload,
            };
        case Actions.AddEmail:
            return {
                ...state,
                email: payload,
            };
        case Actions.SetConfig:
            return {
                ...state,
                remoteConfig: payload,
            };
        case Actions.SetCurrentAddress:
            return {
                ...state,
                currentAddress: action.payload,
            };
        case Actions.SetChain:
            return {
                ...state,
                chain: action.payload,
            };
        case Actions.SetWeb3:
            return {
                ...state,
                web3: action.payload,
            };
        case Actions.SetTokenContract:
            return {
                ...state,
                tokenContract: action.payload,
            };
        case Actions.SetStakingContract:
            return {
                ...state,
                stakingContract: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
