import React, { useEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getValue, getRemoteConfig, fetchAndActivate } from "firebase/remote-config";

import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import App from "./components/App/App";
import { initialState, StateContext, DispatchContext } from "./reducer/constants";
import reducer, { Actions } from "./reducer";

import "./index.scss";

const firebaseConfig = {
    apiKey: "AIzaSyD8XRCLUrS4ypRFN6Oubg0nfxNrECVmbWQ",
    authDomain: "step-app-2bacf.firebaseapp.com",
    databaseURL: "https://step-app-2bacf-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "step-app-2bacf",
    storageBucket: "step-app-2bacf.appspot.com",
    messagingSenderId: "547422727598",
    appId: "1:547422727598:web:e49a576bd8d0272d05ec3f",
    measurementId: "G-YY40ZNM5SD",
};

const AppContainer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const remoteConfig = getRemoteConfig(app);
        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

        remoteConfig.defaultConfig = {
            LoginEnabled: true,
            ReferralFirstLogin: false,
        };

        fetchAndActivate(remoteConfig).then((v) => {
            const loginEnabled = getValue(remoteConfig, "LoginEnabled");
            const referralFirstLogin = getValue(remoteConfig, "ReferralFirstLogin");

            dispatch({
                type: Actions.SetConfig,
                payload: {
                    loginEnabled: loginEnabled.asBoolean(),
                    referralFirstLogin: referralFirstLogin.asBoolean(),
                },
            });
        });
    }, []);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <AppContainer />
        </HashRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
