import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";

import EmailCodeInput from "../EmailCodeInput/EmailCodeInput";
import { sendEmailCode, verifyEmailCode } from "../../utils/email";
import { getEmailInStorage, setAccessToken } from "../../utils/localStorage";
import { StateContext, TIMEOUT_DELAY } from "../../reducer/constants";

import "./EmailCode.scss";
import { setCookie } from "../../utils/cookie";

const SECOND = 1000;
const EMAIL_RESENT_INTERVAL = 30;
const INPUT_LENGTH = 6;

const getCodeLength = (code: string) => code.split("").filter((v) => v !== " ").length;

const EmailCode = () => {
    const { email, remoteConfig } = useContext(StateContext);
    const navigate = useNavigate();
    const [code, setCode] = React.useState("");
    const [currentEmail, setCurrentEmail] = useState<undefined | string>(undefined);
    const [isEmailResent, setIsEmailResent] = useState(false);
    const [timer, setTimer] = useState(0);
    const userEmail = getEmailInStorage();
    const [isError, setIsError] = useState<boolean | null>(null);
    const [isVerifying, setIsVerifying] = useState(false);

    const handleResend = async () => {
        if (!currentEmail) {
            return;
        }
        setIsEmailResent(true);
        setTimer(EMAIL_RESENT_INTERVAL);
        setCode("");
        await sendEmailCode(currentEmail);
    };

    useEffect(() => {
        if (timer === 0) {
            setIsEmailResent(false);
        } else {
            setTimeout(() => setTimer(timer - 1), SECOND);
        }
    }, [timer]);

    const handleVerifyCode = async () => {
        if (!currentEmail) {
            return;
        }
        setIsVerifying(true);
        setIsError(null);
        const response = await verifyEmailCode(currentEmail, code);
        setIsVerifying(false);
        if (response) {
            setIsError(false);
            if (response?.refresh) {
                setCookie("refresh", response.refresh.token, response.refresh.expires);
            }
            setAccessToken(response.access.token, response.access.expires);
            setTimeout(() => {
                if (remoteConfig.referralFirstLogin) {
                    navigate("/dashboard");
                } else if (response.first) {
                    navigate("/ref-code");
                } else {
                    navigate("/dashboard");
                }
            }, TIMEOUT_DELAY);
        } else {
            setIsError(true);
            setTimeout(() => setCode(""), TIMEOUT_DELAY);
        }
    };

    useEffect(() => {
        if (isError) {
            setTimeout(() => setIsError(null), TIMEOUT_DELAY);
        }
    }, [isError]);

    useEffect(() => {
        if (getCodeLength(code) === INPUT_LENGTH) {
            handleVerifyCode();
        }
    }, [code]);

    useEffect(() => {
        if (email) {
            setCurrentEmail(email);
        } else {
            const emailInStorage = getEmailInStorage();
            if (emailInStorage) {
                setCurrentEmail(emailInStorage);
            } else {
                navigate("/email");
            }
        }
    }, []);

    return (
        <div className={cn("email-code", { "email-code--error": isError })}>
            <img alt="logo" className="email-code__logo" src="/img/logo.svg" />
            <div className="email-code__info">
                <div className="email-code__title">
                    {isError ? "The code is wrong, please try again" : "We have send you a code to"}
                </div>
                <div className="userEmail">
                    <span>{userEmail}</span>{" "}
                    <button type="button" onClick={handleResend} disabled={isEmailResent}>
                        {isEmailResent ? ` Resend ${timer}` : "Resend code"}
                    </button>
                </div>
                <EmailCodeInput
                    hasError={isError}
                    needChangeColorOnError
                    acceptOnlyNumber
                    length={INPUT_LENGTH}
                    disabled={getCodeLength(code) === INPUT_LENGTH}
                    value={code}
                    onChange={setCode}
                    inputType="tel"
                />
                {isVerifying || isError === false ? (
                    <div className="loggingText">{isVerifying ? "Checking..." : "Loading..."}</div>
                ) : (
                    <div className="warningText">
                        *If you can't find message in inbox, check spam or promotions folder.
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailCode;
