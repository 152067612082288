import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";

import { DispatchContext, StateContext } from "../../reducer/constants";
import { addWallet, getUserInformation } from "../../utils/email";
import { Actions } from "../../reducer";
import { getTimeLeft } from "../../utils/time";
import Header from "../Header/Header";

import "./EmailDone.scss";
import { numWithCommas } from "../../utils/numWithCommas";

interface Props {
    onConnect: () => void;
    onDisconnect: () => void;
}

const EmailDone = ({ onConnect, onDisconnect }: Props) => {
    const { userInfo, currentAddress } = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const navigate = useNavigate();
    const [msUntilEvent, setMsUntilEvent] = useState(0);
    // eslint-disable-next-line no-undef
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
    const [currentWallet, setCurrentWallet] = useState<string | undefined>("");
    const [isError, setIsError] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const walletInputRef = useRef(null);

    const handleCopyGenUrl = () => {
        if (userInfo) {
            setIsLinkCopied(false);
            navigator.clipboard.writeText(userInfo.id);
            setIsCopied(true);
        }
    };
    const handleCopyFullGenUrl = () => {
        if (userInfo) {
            setIsCopied(false);
            navigator.clipboard.writeText(`https://gg.sports.gg/?r=${userInfo.id}`);
            setIsLinkCopied(true);
        }
    };

    useEffect(() => {
        if (userInfo?.fatConfig?.start) {
            const newMsUntilEvent = userInfo.fatConfig.start * 1000 - Date.now();
            if (intervalId) {
                setIntervalId(undefined);
                clearInterval(intervalId);
                setMsUntilEvent(newMsUntilEvent);

                const newIntervalId = setInterval(() => {
                    setMsUntilEvent((v) => (v ? Math.max(v - 1000, 0) : newMsUntilEvent));
                }, 1000);

                setIntervalId(newIntervalId);
            } else {
                const newIntervalId = setInterval(() => {
                    setMsUntilEvent((v) => (v ? Math.max(v - 1000, 0) : newMsUntilEvent));
                }, 1000);

                setIntervalId(newIntervalId);
            }
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [userInfo]);

    const updateUserInfo = async () => {
        const information = await getUserInformation();
        dispatch({ type: Actions.AddUserInfo, payload: information });
    };

    useEffect(() => {
        document.addEventListener("visibilitychange", updateUserInfo);

        return () => {
            document.removeEventListener("visibilitychange", updateUserInfo);
        };
    }, [dispatch]);

    const onMount = async () => {
        if (!userInfo) {
            const information = await getUserInformation();
            dispatch({ type: Actions.AddUserInfo, payload: information });

            if (!information?.email) {
                navigate("/email");
            }
        }
    };

    useEffect(() => {
        onMount();
    }, []);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentWallet(e.target.value.trim());
    };

    useEffect(() => {
        if (currentAddress && currentWallet !== currentAddress) {
            setCurrentWallet(currentAddress);
            // @ts-ignore
            walletInputRef.current.focus();
        }
    }, [currentAddress]);

    const handleApprove = async () => {
        if (currentWallet && Web3.utils.isAddress(currentWallet)) {
            setIsError(false);
            const response = await addWallet(currentWallet);
            if (response) {
                const userInfoPayload = await getUserInformation();

                dispatch({ type: Actions.AddUserInfo, payload: { ...userInfoPayload, wallet: currentWallet } });
            } else {
                setCurrentWallet("");
                setIsError(true);
            }
        } else {
            setCurrentWallet("");
            setIsError(true);
        }
    };

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false);
            }, 5000);
        }
    }, [isError]);

    if (!userInfo) {
        return (
            <div className="email-done">
                <div className="email-done__content">
                    <img alt="logo" className="email-done__logo" src="/img/logo.svg" />
                </div>
            </div>
        );
    }

    return (
        <div className="email-done">
            <div className="email-done__content">
                <Header onConnect={onConnect} onDisconnect={onDisconnect} />
                <div className="email-done__fat">
                    <span className="fatText">GG balance: </span>
                    <div className="email-done__fat__value">{numWithCommas(userInfo.fatSum.toLocaleString())}</div>
                    <div className="email-done__fat__income">
                        <span className="incomeCount">+{userInfo.fatDaily.toLocaleString()} </span>GG PER DAY
                    </div>
                    {msUntilEvent > 0 && (
                        <div className="email-done__fat__income__special">
                            <span className="incomeCount">{getTimeLeft(Math.floor(msUntilEvent / 1000))}</span> Before
                            start
                        </div>
                    )}
                </div>
                <div className="referralCodeWrapper">
                    <div className="label">Your Referral Code:</div>
                    <div className="refText">
                        <span>{userInfo.id}</span>
                        <button type="button" onClick={handleCopyGenUrl} className="copyBtn">
                            {isCopied ? "Copied" : "Copy"}
                        </button>
                    </div>
                </div>
                <span className="copyLink" onClick={handleCopyFullGenUrl}>
                    {isLinkCopied ? "Share link copied" : "Copy share link"}
                </span>
                <div className="actionsWrapper">
                    <div className="leftAction">
                        <div className="label">Referals:</div>
                        <div className="referalsCount">
                            {userInfo.referred} users (+{userInfo.fatConfig.refer} GG/day)
                        </div>
                    </div>
                    <div className="rightAction">
                        <div className="label">{userInfo.wallet ? "Wallet Added" : "Enter your wallet"}</div>
                        <div className="inputWrapper">
                            <input
                                ref={walletInputRef}
                                type="text"
                                className="rightActionInput"
                                placeholder="0x00000000000"
                                value={currentWallet}
                                onChange={handleEmailChange}
                            />
                            <button
                                type="button"
                                className="rightActionBtn"
                                onClick={handleApprove}
                                disabled={!currentWallet?.length || isError}
                            >
                                {isError ? "Incorrect wallet" : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailDone;
