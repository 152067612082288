import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getUserInformation, isEmail, sendEmailCode } from "../../utils/email";
import { DispatchContext, StateContext } from "../../reducer/constants";
import { Actions } from "../../reducer";
import { setEmailToStorage } from "../../utils/localStorage";

import "./Email.scss";

const Email = () => {
    const dispatch = useContext(DispatchContext);
    const { email, userInfo, remoteConfig } = useContext(StateContext);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const navigate = useNavigate();
    const [inputEmail, setInputEmail] = useState<string>(email || "");

    const [isLoading, setIsLoading] = useState(false);
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value.trim();
        setInputEmail(newEmail);
        dispatch({ type: Actions.AddEmail, payload: newEmail });
        setEmailToStorage(newEmail);
    };

    const handleApprove = async () => {
        if (email) {
            try {
                setIsLoading(true);
                const res = await sendEmailCode(email);
                if (res) {
                    setIsLoading(false);
                    navigate(remoteConfig.referralFirstLogin ? "/ref-code" : "/email-code");
                }
            } catch (e) {
                setIsLoading(false);
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && isEmail(email) && !isLoading) {
            handleApprove();
        }
    };

    const onMount = useCallback(async () => {
        if (!userInfo) {
            try {
                const information = await getUserInformation();
                dispatch({ type: Actions.AddUserInfo, payload: information });
                setIsDataLoading(false);

                if (information?.email) {
                    navigate("/dashboard");
                }
            } catch (e) {
                setIsDataLoading(false);
            }
        } else {
            setIsDataLoading(false);
        }
    }, []);

    useEffect(() => {
        onMount();
    }, [onMount]);

    if (isDataLoading) {
        return (
            <div className="email">
                <img alt="sun" className="email__logo" src="/img/logo.svg" />
                <div className="email__title">Loading...</div>
            </div>
        );
    }

    return (
        <div className="email">
            <img alt="logo" className="email__logo" src="/img/logo.svg" />
            {remoteConfig.loginEnabled ? (
                <>
                    <div className="email__title">So, let’s start from e-mail</div>
                    <input
                        type="email"
                        className="email__input"
                        value={inputEmail}
                        onChange={handleEmailChange}
                        onKeyDown={handleKeyDown}
                        placeholder="johndow@inbox.com"
                    />
                    <div
                        className="email__doneBtn"
                        onClick={handleApprove}
                        style={{ opacity: !isEmail(email) || isLoading ? 0.6 : 1 }}
                    >
                        <span>{isLoading ? "Sending..." : "Send code"}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="4" y1="12" x2="20" y2="12" />
                            <polyline points="14 6 20 12 14 18" />
                        </svg>
                    </div>
                </>
            ) : (
                <div className="email__title">Registration is temporary closed.</div>
            )}
        </div>
    );
};

export default Email;
